<template>
  <div class="home" style="height:100%">
    <el-container style="height:100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main style="padding: 0; margin: 0 auto; width: 100%; overflow: hidden">
        <iframe
          ref="drawer1iframe"
          onload="this.style.height= (window.innerHeight - 60) +'px';"
          src="https://doc.textualesg.com"
          style="width: 100%; border: hidden"
          title="Document of TextualESG"
        ></iframe>
      </el-main>
    </el-container>
    <!--    <FooterLayout></FooterLayout>-->

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayout from '../../components/FooterLayout'

export default {
  name: 'document',
  components: {
    HeaderLayout,
    FooterLayout
  }
}
</script>

<style>
/*.el-header {*/
/*  background-color: #1E90FF;*/
/*  color: #333;*/
/*}*/

/*.el-main {*/
/*  background-color: #E9EEF3;*/
/*  color: #333;*/
/*  text-align: center;*/
/*}*/
</style>
